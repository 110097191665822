import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Navbar from './global/navbar';
import PageHeader from './global/page-header';
import Gallery from './gallery-components/gallery';
import Footer from './global/footer';

const GalleryPage = () => {
    const { rpdata } = useContext(GlobalDataContext);
    return <div>
        <Navbar />
        <PageHeader headertitle="Gallery" Subheader="Gallery" bgimg={rpdata?.stock?.[9]} />
        <Gallery />
        <Footer />
    </div>
}

export default GalleryPage

