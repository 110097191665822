import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

const Map = (props) => {
 const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="contact-page-map mg-top-100">

        <h2 className="text-center">We Cover</h2>
        <div className="row" >
        {
          rpdata?.dbPrincipal?.location?.map((item, index) => {
            return(
                <div className="col-lg-4 col-md-6"key={index}>
                  <h3 className="text-center">{item.address}</h3>
                <iframe src={item.url} />
                </div>
              
            )
          }
          )
        }
        </div>
      </div>
    </div>
  )
}

export default Map
