import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

//

const LiveChat = (props) => {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <div>
<div class="elfsight-app-1c8c7181-1d4f-4595-a175-32266d4b995d"></div>
      <div className="visor_Counter">
<div class="elfsight-app-b8a56f1e-13f0-420d-a542-091d4e218b2d"></div>
      </div>
    </div>
  );
};

export default LiveChat;
